import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  position: relative;
  
  background-image: ${({ noResults }) => noResults ? "url('/Search-Page/NotFound.png')" : 'none'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(99.31deg, #0D1016 -9.79%, #111E36 20.84%, #0D1016 94.54%),
                linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, #111E36 5.44%, rgba(13, 16, 22, 0) 93.15%);
    z-index: -1; 
  }

  color: white;
  height: 100vh; 
  width: 100vw;
  overflow: hidden;
  position: relative;
`;



export const ScrollContent = styled.div`
  padding: 0;
  flex-grow: 1;
  overflow-y: auto; 
  z-index: 2;
  width: 100%; 
  box-sizing: border-box;
  
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  
  &::-webkit-scrollbar {
    display: none;  /* WebKit */
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3vh;
`;

export const BackButton = styled.button`
  position: relative;
  right: 5%;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  transition: scale 0.2s ease;
  &:hover {
    scale: 1.1; 
  }
`;

export const BackButtonImage = styled.img`
  width: 4vh;
  height: 4vh;
`;

export const InputContainer = styled.div`
  height:  7vh;
  margin: 3vh 0;
  margin-top: 5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  top: 2vh;
`;

export const PoplarTitle = styled.label`
  display: block;
  position: relative;
  margin-bottom: 2vh;
  left: 5%;
  font-weight: 800;
  font-family: 'Rounded Mplus 1c', sans-serif;
  width: 90%;
  font-size: 18px;
  color: white;
`;

export const SectionTitle = styled.label`
  display: block;
  position: relative;
  left: 5%;
  font-weight: 800;
  font-family: 'Rounded Mplus 1c', sans-serif;
  width: 90%;
  font-size: 22px;
  color: white;
`;

export const SearchInput = styled.input`
  margin-top: 2vh;
  padding: 1.5vh; 
  padding-left: 2vh;
  font-size: 14px; 
  font-weight: 500;
  font-family: 'Rounded Mplus 1c', sans-serif;
  width: 90%;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.2); 
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.1); 
  color: white;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5); 
    font-size: 16px;
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.3);
  }

  &:focus {
    outline: none;
    border-color: #0088cc; 
  }

  @layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  width: 90%;
  background: 
    url('/Main-Page/Footer/logo.png') no-repeat center, /* Image background */
    linear-gradient(99.31deg, rgba(13, 16, 22, 0.5) -9.79%, rgba(17, 30, 54, 0.5) 20.84%, rgba(13, 16, 22, 0.5) 94.54%),
    linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.8) 5.44%, rgba(13, 16, 22, 0) 93.15%);
    
  backdrop-filter: blur(2vh);
  -webkit-backdrop-filter: blur(2vh);
  background-size: contain; /* Image remains the same size */
  background-position: center; /* Ensures the image stays in the center */
  border-radius: 10px;
  overflow-y: auto; 
  z-index: 1000;
  margin-top: 10vh;
`;

export const DropdownItem = styled.div`
  padding: 2vh;
  font-size: 18px;
  font-family: 'Rounded Mplus 1c', sans-serif;
  color: white;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.45); /* Optional background color for items */

  &:hover {
    background-color: rgba(0, 0, 0, 0.7); /* Hover effect: lighter than the background */
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Optional bottom border */
  }
`;

export const GameGrid = styled.div`
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5vh;
  margin-bottom: 11vh;
`;

export const GameCard = styled.div`
  background-color: #121429;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  overflow: hidden; 
  &:hover {
    transform: scale(1.05);
  }
`;

export const GameImage = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 3/4; 
  object-fit: contain;
  border-radius: 10px;
`;

export const NoGamesFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

`;

export const NoGamesText = styled.h2`
  font-size: 28px;
  font-weight: bold;
  color: white;
  margin-bottom: 0vh; 
  font-weight: 800;
  font-family: 'Rounded Mplus 1c', sans-serif;
`;

export const CheckText = styled.p`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 3vh;   
  margin-top: 1vh; 
  font-weight: 400;
  font-family: 'Rounded Mplus 1c', sans-serif;
`;
