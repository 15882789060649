const serverUrl = 'https://api.betongame.club';

const extractChatIdFromInitData = (initData) => {
  const params = new URLSearchParams(initData);
  const userJsonString = params.get('user');
  const user = JSON.parse(userJsonString); 
  return user.id; 
};

const withdrawFunds = async (recipientAddress, amount, body, initData) => {
  const userId = extractChatIdFromInitData(initData);
  const response = await fetch(`${serverUrl}/api/transactions/withdraw`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
    body: JSON.stringify({
      recipientAddress,
      amount: parseFloat(amount),
      body: body || 'Withdrawal from Casino', 
      userId
    }),
  });
  if (!response.ok) {
    const errorMessage = await response.json();
    console.error('Server error:', errorMessage);
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

const sendTonTransaction = async (recipientAddress, amount, body, initData) => {
  const response = await fetch(`${serverUrl}/api/transactions/SendTon`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
    body: JSON.stringify({
      recipientAddress,
      amount: parseFloat(amount),
      body: body || 'Depsit for Casino'
    }),
  });

  if (!response.ok) {
    const errorMessage = await response.json();
    console.error('Server error:', errorMessage);
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

const saveTransaction = async (initData, walletAddress, amount) => {
  const userId = extractChatIdFromInitData(initData);
  const response = await fetch(`${serverUrl}/api/transactions/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
    body: JSON.stringify({
      userId,
      walletAddress,
      amount, 
      transactionType: 'Deposit'
    }),
  });
  if (!response.ok) {
    const errorMessage = await response.json();
    console.error('Server error:', errorMessage);
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

export { withdrawFunds, sendTonTransaction, saveTransaction}