import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MainContainer,
  HeaderContainer,
  ScrollContent,
  BackButton,
  BackButtonImage,
  PoplarTitle,
  GameGrid,
  GameCard,
  GameImage,
  SearchContainer,
  InputContainer,
  SearchInput,
  SectionTitle,
  Dropdown,
  DropdownItem,
  NoGamesFoundContainer,
  NoGamesText,
  CheckText
} from './Search.styles';
import { getUserBalance, startGameSession } from '../../services/userService';
import gamesData from '../../data/Bgaming.json';

const SearchGames = ({ initDataRaw, language }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredGames, setFilteredGames] = useState(gamesData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [balance, setBalance] = useState(null);
  const navigate = useNavigate();
  console.log(balance);

  useEffect(() => {
    const fetchBalance = async () => {  
      try {
        const response = await getUserBalance(initDataRaw);
        setBalance(response.balance); 
      } catch (error) {
      } 
    };

    fetchBalance();
  }, [initDataRaw]);
  useEffect(() => {
    if (searchQuery === '') {
      setFilteredGames(gamesData);
      setIsDropdownOpen(false);
      setSelectedGame(null); 
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      const filtered = gamesData.filter((game) =>
        game.name.toLowerCase(  ).includes(lowerQuery)
      );
      setFilteredGames(filtered);
      setIsDropdownOpen(filtered.length > 0);

      if (filtered.length === 0) {
        setSelectedGame(null);
      }
    }
  }, [searchQuery]);

  const handleSelectGame = (game) => {
    setSearchQuery(game.name);
    setSelectedGame(game); // Сохраняем выбранную игру
    setTimeout(() => {
      setIsDropdownOpen(false); // Закрываем dropdown после выбора игры
    }, 5);
  };

  const handleStartGame = async (gameIdentifier) => {
    try {
      const gameUrl = await startGameSession(gameIdentifier, initDataRaw, balance, language);
      navigate('/game', { state: { gameUrl } });
    } catch (error) {
      console.error('Ошибка при создании игровой сессии:', error);
    }
  };
  return (
    <MainContainer noResults={filteredGames.length === 0}>
      <ScrollContent>
        <SearchContainer>
          <HeaderContainer>
            <SectionTitle>{language === 'ru' ? 'Поиск игр' : 'Search games'}</SectionTitle>
            <BackButton onClick={() => navigate('/')}>
              <BackButtonImage src="/Wallet-Page/Deposit/backButton.png" alt="Back" size="28px" />
            </BackButton>
          </HeaderContainer>
          <InputContainer>
            <SearchInput
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={language === 'ru' ? 'Введите название игры' : 'Enter game name'}
            />
            {isDropdownOpen && (
              <Dropdown>
                {filteredGames.map((game) => (
                  <DropdownItem key={game.id} onClick={() => handleSelectGame(game)}>
                    {game.name}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </InputContainer>
        </SearchContainer>

        {selectedGame ? (
          <GameGrid>
            <GameCard key={selectedGame.id} onClick={() => handleStartGame(selectedGame.gameIdentifier)}>
              <GameImage src={selectedGame.src} alt={selectedGame.name} />
            </GameCard>
          </GameGrid>
        ) : (
          <>
            {filteredGames.length > 0 ? (
              <>
                <PoplarTitle>{language === 'ru' ? 'Популярные игры' : 'Popular Games'}</PoplarTitle>
                <GameGrid>
                  {filteredGames.map((game) => (
                    <GameCard key={game.id} onClick={() => handleStartGame(game.gameIdentifier)}>
                      <GameImage src={game.src} alt={game.name} />
                    </GameCard>
                  ))}
                </GameGrid>
              </>
            ) : (
              <NoGamesFoundContainer>
                <NoGamesText>{language === 'ru' ? 'Ничего не найдено' : 'No games found'}</NoGamesText>
                <CheckText>{language === 'ru' ? 'Проверьте правильность написания' : 'Check your spelling'}</CheckText>
              </NoGamesFoundContainer>
            )}
          </>
        )}
      </ScrollContent>
    </MainContainer>
  );
};

export default SearchGames;
