import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MainContainer,
  FixedBackground,
  WhiteBarWithDots,
  GameGrid,
  SectionTitle,
  GameCard,
  GameImage,
  ScrollContent,
  GradientGrid
} from './Main.styles';
import Footer from '../Footer/Footer';
import TabsNavigation from '../TabsNavigation/TabsNavigation'; 
import { getPopularGames, getNewGames } from '../../services/userService';
import Loader from '../Loader/Loader'; 

const Main = ({ initDataRaw, language }) => {
  const [popularGames, setPopularGames] = useState([]);
  const [newGames, setNewGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const navigate = useNavigate();
  const [selectedProviders, setSelectedProviders] = useState([]);

  useEffect(() => {
    const savedProviders = localStorage.getItem('selectedProviders');
    if (savedProviders) {
      setSelectedProviders(JSON.parse(savedProviders));
    } else {
      const allProviders = ['Bgaming'];
      setSelectedProviders(allProviders);
      localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
    }
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      setIsLoading(true); 
      if (selectedProviders.length === 0) {
        setIsLoading(false);
        return;
      }

      try {
        const provider = selectedProviders[0]; 
        const popularGamesData = await getPopularGames(initDataRaw, provider);
        setPopularGames(popularGamesData);

        const newGamesData = await getNewGames(initDataRaw, provider);
        setNewGames(newGamesData);
      } catch (error) {
        console.error("Ошибка при загрузке игр:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGames();
  }, [initDataRaw, selectedProviders]);

  const handleSelectGame = (game) => {
    navigate('/game', { 
      state: { 
        gameIdentifier: game.game_id, 
        gameImage: game.src, 
        gameName: game.name, 
        providerName: game.provider
      } 
    });
  };

  const renderGames = (games) => {
    return games.map((game) => (
      <GameCard key={game.id} onClick={() => handleSelectGame(game)}>
        <GameImage
          src={game.src || '/path/to/default-image.png'}
          alt={game.game_identifier || 'Game'}
        />
      </GameCard>
    ));
  };

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <MainContainer>
      <ScrollContent>
        <FixedBackground />
        <WhiteBarWithDots />
        <TabsNavigation language={language} />
        <GradientGrid>
          <SectionTitle>{language === 'ru' ? 'Популярное' : 'Popular'}</SectionTitle>
          <GameGrid>
            {popularGames.length > 0 ? renderGames(popularGames) : 
              <SectionTitle  style={{ fontSize: '1rem' }}>{language === 'ru' ? 'Нет игр' : 'No games available'}</SectionTitle>}
          </GameGrid>
        </GradientGrid>
        <GradientGrid>
          <SectionTitle>{language === 'ru' ? 'Новинки' : 'New'}</SectionTitle>
          <GameGrid>
            {newGames.length > 0 ? renderGames(newGames) : 
              <SectionTitle style={{ fontSize: '1rem' }}>{language === 'ru' ? 'Нет новых игр' : 'No new games available'}</SectionTitle>}
          </GameGrid>
        </GradientGrid>

        <Footer language={language} />
      </ScrollContent>
    </MainContainer>
  );
};

export default Main;
