import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Main from './components/Main/Main';
import Wallet from './components/wallet/wallet';
import BalanceBar from './components/BalanceBar/BalanceBar';
import GamePage from './components/GamePage/GamePage';
import ProviderSelector from './components/providers/Provider';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import SearchGames from './components/Search/Search';
import History from './components/History/History';
import Favorite from './components/Favorite/Favorite';
import NewGames from './components/NewGames/NewGames';
import PopularGames from './components/PopularGames/PopularGames';
import TransactionHistory from './components/wallet/TransactionHistory/TransactionHistory';
import Tournaments from './components/Tournaments/Tournaments';

const AppContainer = styled.div`
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  overflow-x: hidden;
`;

const AppContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const AppContentWithRouter = () => {
  const [initDataRaw, setInitDataRaw] = useState('');
  const [language, setLanguage] = useState('');
  const [selectedProviders, setSelectedProviders] = useState([]);  
  const location = useLocation(); 

  useEffect(() => {
    console.error = () => {};
    console.warn = () => {};
    console.log = () => {};
    
    window.Telegram.WebApp.expand(); 
    window.Telegram.WebApp.isClosingConfirmationEnabled = true;

    window.Telegram.WebApp.disableVerticalSwipes(); 

    const initDataRaw = window.Telegram.WebApp.initData;
    const params = new URLSearchParams(initDataRaw);
    const userJsonString = params.get('user'); 
  
    if (userJsonString) {
      const user = JSON.parse(decodeURIComponent(userJsonString)); 
      const language = user.language_code || 'en'; 
      setLanguage(language); 
    }

    setInitDataRaw(initDataRaw); 

    const closingBehaviorData = JSON.stringify({
      eventType: 'web_app_setup_closing_behavior',
      eventData: {
        need_confirmation: true,  
      },
    });

    window.parent.postMessage(closingBehaviorData, '*');

    return () => {
      window.Telegram.WebApp.enableVerticalSwipes();
    };
  }, []);

  return (
    <AppContainer>
      <AppContent>
        <Routes>
          <Route path="/" element={<Main initDataRaw={initDataRaw} language={language} selectedProviders={selectedProviders}/>} />
          <Route path="/game" element={<GamePage initDataRaw={initDataRaw} language={language}/>} />
          <Route path="/search" element={<SearchGames initDataRaw={initDataRaw} language={language}/>} />
          <Route path="/history" element={<History initDataRaw={initDataRaw} language={language}/>} />
          <Route path="/providers" element={<ProviderSelector setSelectedProviders={setSelectedProviders} language={language} />} /> 
          <Route path="/wallet" element={<Wallet initDataRaw={initDataRaw} language={language} />} />
          <Route path="/favorites" element={<Favorite initDataRaw={initDataRaw} language={language} />} />
          <Route path="/newgames" element={<NewGames initDataRaw={initDataRaw} language={language} />} />
          <Route path="/popularGames" element={<PopularGames initDataRaw={initDataRaw} language={language} />} />
          <Route path="/transaction-history" element={<TransactionHistory initDataRaw={initDataRaw} language={language} />} />
          <Route path="/tournaments" element={<Tournaments />} />
        </Routes>
      </AppContent>
      {location.pathname !== '/providers' && location.pathname !== '/wallet' && location.pathname !== '/game' && <BalanceBar initDataRaw={initDataRaw} language={language} />}
    </AppContainer>
  );
};

const App = () => (
  <TonConnectUIProvider 
    manifestUrl="https://betongame.club/tonconnect-manifest.json"
    enableAndroidBackHandler={false}
  >
    <Router>
      <AppContentWithRouter />
    </Router>
  </TonConnectUIProvider>
);

export default App;
