import React, { useState } from 'react';
import { 
  PopUpContainer, 
  PopUpHeader, 
  PopUpTitle, 
  ChangeText, 
  WalletOption, 
  WalletText, 
  WalletRadioButton, 
  ContinueButton,
  WalletIcon,
  Overlay
} from './PaymentMethodPopup.styles'; 
import WalletDeletionPopup from './WalletDeletionPopup'; // Import the wallet deletion popup

const PaymentMethodPopup = ({ wallet, activeTab,language, onClose }) => {
  const [isChecked, setIsChecked] = useState(true); // Initialize with checked state
  const [isDeletionPopupOpen, setIsDeletionPopupOpen] = useState(false); // State to handle deletion popup

  const toggleChecked = () => {
    setIsChecked(!isChecked);
  };

  const handleOpenDeletionPopup = () => {
    setIsDeletionPopupOpen(true);  // Open deletion popup
  };

  const handleCloseDeletionPopup = () => {
    setIsDeletionPopupOpen(false);  // Close deletion popup
    onClose();  // Optionally close the entire popup when the deletion popup is closed
  };

  return (
    <>
      <Overlay />

      {!isDeletionPopupOpen && (
        <PopUpContainer>
            <ChangeText onClick={handleOpenDeletionPopup}>{language === 'ru' ? 'Изменить' : 'Change'}</ChangeText> 
                <PopUpHeader>{activeTab === 'deposit' ? (
                    <PopUpTitle>{language === 'ru' ? 'Способы пополнения' : 'Payment Method'}</PopUpTitle>
                    ) : (
                    <PopUpTitle>{language === 'ru' ? 'Способы вывода' : 'Withdraw Method'}</PopUpTitle>
                    )}
                </PopUpHeader>

          <WalletOption onClick={toggleChecked}>
            <WalletIcon src="/Wallet-Page/Deposit/Usdt.png" alt="USDT Icon" /> 
            <WalletText>{wallet.name} {wallet.shortenedAddress}</WalletText>
            <WalletRadioButton checked={isChecked} />
          </WalletOption>

          <ContinueButton onClick={onClose}>{language === 'ru' ? 'Продолжить' : 'Continue '}</ContinueButton>
        </PopUpContainer>
      )}

      {isDeletionPopupOpen && (
        <WalletDeletionPopup 
          wallets={[wallet]} 
          language={language}
          onClose={handleCloseDeletionPopup}
        />
      )}
    </>
  );
};

export default PaymentMethodPopup;
