import React, { useState, useEffect } from 'react';
import { THEME, TonConnectUIProvider, useTonConnectUI, useTonAddress, useTonConnectModal } from '@tonconnect/ui-react';
import { 
  WalletContainer, 
  Input, 
  InputContainer, 
  InputLabel, 
  ButtonTonText, 
  BackButton, 
  BackButtonImage,
  TabsContainer, 
  TabButton, 
  AmountOptions, 
  BonusImage, 
  WalletConnectRow, 
  WalletConnectText, 
  MinAmountLabel,
  IconLeft, 
  IconRight, 
  TopLabel 
} from './Wallet.styles';
import PaymentMethodPopup from './PopUps/PaymentMethodsPopUp';
import { updateUserBalance, getMinDeposit, updateWagering, getRemainingWagering } from '../../services/userService';  // Импортируем новый метод
import { withdrawFunds, sendTonTransaction } from '../../services/tonServices'; 
import { useNavigate } from 'react-router-dom';

const Wallet = ({ initDataRaw, language }) => {
  const [amount, setAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [minDeposit, setMinDeposit] = useState(1.62); 
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonAddress();
  const { open } = useTonConnectModal();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('deposit');
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  useEffect(() => {
    const fetchMinDeposit = async () => {
      try {
        const deposit = await getMinDeposit(initDataRaw);
        setMinDeposit(deposit);
      } catch (error) {
        console.error('Ошибка при получении минимального депозита:', error);
      }
    };

    fetchMinDeposit();
  }, [initDataRaw]);

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  const handleConnectWallet = async () => {
    open();
  };

  const handleSendTransaction = async () => {
    if (!wallet) {
      handleConnectWallet();
      return;
    }

    if (!amount || isNaN(amount) || parseFloat(amount) < minDeposit / 100) {
      return;
    }

    try {
      const transaction = await sendTonTransaction(wallet, amount, 'Пополнение баланса', initDataRaw);
      if (!transaction || !transaction.messages || !Array.isArray(transaction.messages)) {
        throw new Error('Некорректная структура ответа сервера');
      }

      await tonConnectUI.sendTransaction({
        validUntil: Math.floor(Date.now() / 1000) + 60,
        messages: transaction.messages.map((msg) => ({
          address: msg.to,
          amount: msg.value,
          payload: msg.payload,
          bounce: true,
        })),
      });

      await updateUserBalance(initDataRaw, parseFloat(amount) * 100);
      await updateWagering(initDataRaw, parseFloat(amount));

    } catch (error) {
      console.error('Error sending transaction:', error);
    }
  };

  const handleWithdraw = async () => {
    if (!wallet) {
      console.log('Кошелек не привязан. Попробуйте снова.');
      return;
    }
  
    if (!withdrawAmount || parseFloat(withdrawAmount) <= 0) {
      console.log('Введите корректную сумму для вывода.');
      return;
    }
  
    try {
      const remainingWagering = await getRemainingWagering(initDataRaw)/100;
      if (remainingWagering > 0) {
        console.log('Вывод средств запрещен, пока есть оставшийся отыгрыш.');
        return;
      }
  
      const amountToSend = parseFloat(withdrawAmount).toString();
      const result = await withdrawFunds(wallet, amountToSend, 'Withdrawal from Casino', initDataRaw);
    } catch (error) {
      console.error('Ошибка при обработке вывода средств:', error);
    }
  };
  

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const togglePopUp = () => {
    setIsPopUpOpen(!isPopUpOpen);
  };

  return (
    <TonConnectUIProvider
      manifestUrl="https://raw.githubusercontent.com/daanicccch/tonconnect-manifestBcasino.json/main/tonconnect-manifest.json"
      uiPreferences={{ theme: THEME.DARK }}
    >
      <WalletContainer>
        <BackButton onClick={() => navigate(-1)}>
          <BackButtonImage src="/Wallet-Page/Deposit/backButton.png" alt="Back" />
        </BackButton>
        <TabsContainer>
          <TabButton active={activeTab === 'deposit'} isFirst={true} onClick={() => handleTabClick('deposit')}>
            {language === 'ru' ? 'Пополнение' : 'Deposit'}
          </TabButton>
          <TabButton active={activeTab === 'withdraw'} isFirst={false} onClick={() => handleTabClick('withdraw')}>
            {language === 'ru' ? 'Вывод' : 'Withdraw'}
          </TabButton>
        </TabsContainer>

        {activeTab === 'deposit' ? (
          <TopLabel>{language === 'ru' ? 'Способ пополнения' : 'Payment Method'}</TopLabel>
        ) : (
          <TopLabel>{language === 'ru' ? 'Сумма вывода' : 'Withdraw Details'}</TopLabel>
        )}
        <WalletConnectRow walletConnected={!!wallet} onClick={!wallet ? handleSendTransaction : togglePopUp}>
          <WalletConnectText>
            <IconLeft src="/Wallet-Page/Deposit/plus.png" alt="+" />
            {wallet ? shortenAddress(wallet) : language === 'ru' ? 'Привязать кошелёк' : 'Connect Wallet'}
          </WalletConnectText>
          <IconRight src="/Wallet-Page/Deposit/back.png" alt=">" />
        </WalletConnectRow>

        {activeTab === 'deposit' && (
          <>
            <InputContainer>
              <InputLabel>{language === 'ru' ? 'Сумма пополнения' : 'Deposit Amount'}</InputLabel>
              <Input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder={language === 'ru' ? '0.00' : '0.00'}
              />
              <MinAmountLabel>{language === 'ru' ? `Мин. сумма: ${minDeposit / 100}` : `Min. amount: ${minDeposit / 100}`}</MinAmountLabel>
              <AmountOptions>
                {[50, 100, 150, 200, 1500].map((option) => (
                  <button key={option} onClick={() => setAmount(option)}>
                    {option}
                  </button>
                ))}
              </AmountOptions>
              <BonusImage src="/Wallet-Page/Deposit/freespin.png" alt="Bonus Image" />
              <ButtonTonText onClick={handleSendTransaction}>
                {language === 'ru' ? 'Пополнить' : 'Deposit'}
              </ButtonTonText>
            </InputContainer>
          </>
        )}

        {activeTab === 'withdraw' && (
          <>
            <InputContainer>
              <InputLabel>{language === 'ru' ? 'Сумма вывода' : 'Withdraw Amount'}</InputLabel>
              <Input
                type="number"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                placeholder={language === 'ru' ? '0.00' : '0.00'}
              />
              <ButtonTonText onClick={handleWithdraw}>
                {language === 'ru' ? 'Вывести' : 'Withdraw'}
              </ButtonTonText>
            </InputContainer>
          </>
        )}

        {isPopUpOpen && wallet && (
          <PaymentMethodPopup
            language={language}
            activeTab={activeTab}
            wallet={{
              name: 'Tether (TON)',
              shortenedAddress: shortenAddress(wallet),
              address: wallet,
              language,
            }}
            onClose={togglePopUp}
          />
        )}
      </WalletContainer>
    </TonConnectUIProvider>
  );
};

export default Wallet;
