import React, { useState, useEffect } from 'react';
import { BalanceBarContainer, BalanceContainer, IconContainer, NavItem, NavText, BalanceText, BalanceAmount, Icon, WalletButton, WalletIcon, WalletButtonShadow } from './BalanceBar.styles';
import { getUserBalance } from '../../services/userService';
import { useNavigate } from 'react-router-dom';

const BalanceBar = ({initDataRaw, language}) => {
  const [balance, setBalance] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchBalance = async () => {  
      try {
        const response = await getUserBalance(initDataRaw);
        setBalance(response.balance); 
      } catch (error) {
      } 
    };

    fetchBalance();
  }, [initDataRaw]);

  const handleWalletNavigation = () => {
    navigate('/wallet');
  };

  const handleSearchNavigation = () => {
    navigate('/search', { state: { balance } });
  };

  const handleProviderNavigation = () => {
    navigate('/providers');
  };
  
  return (
    <BalanceBarContainer>
      <IconContainer>
        <NavItem onClick={handleSearchNavigation}>
          <Icon src="/Main-Page/BalanceBar/searchIcon.png" alt="Поиск" />
          <NavText>{language === 'ru' ? 'Поиск' : 'Search'}</NavText>
        </NavItem>
        <NavItem onClick={handleProviderNavigation}>
          <Icon src="/Main-Page/BalanceBar/settingsIcon.png" alt="Провайдеры" />
          <NavText>{language === 'ru' ? 'Провайдеры' : 'Providers '}</NavText>
        </NavItem>
      </IconContainer>
      <BalanceContainer>
        <div>
          <BalanceText>{language === 'ru' ? 'Баланс USDT' : 'Balance USDT'}</BalanceText>
          <BalanceAmount>{balance/100}</BalanceAmount>
        </div>
        <WalletButtonShadow>
          <WalletButton onClick={handleWalletNavigation}>
            <WalletIcon src="/Main-Page/BalanceBar/walletIcon.png" alt="Wallet" />
          </WalletButton>
        </WalletButtonShadow>
      </BalanceContainer>
    </BalanceBarContainer>
  );
};

export default BalanceBar;
