import styled from 'styled-components';

export const FooterContainer = styled.footer`
  width: 100%;
  margin-top: 2vh;
  background-color: #101a2e;
  color: #fff;
  text-align: center;
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
`;

export const FooterLogo = styled.img`
  width: 100%;
  margin-bottom: 1vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const SupportButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5vh;
  width: 100%;
  max-width: 100%;
  padding: 0 2vh; /* Добавляем отступы по бокам */
  box-sizing: border-box; /* Включаем отступы в расчет ширины */
  
  @media (max-width: 344px) {
    flex-direction: column; /* На узких экранах кнопки будут размещаться вертикально */
    gap: 1.5vh;
    align-items: center; /* Выравнивание кнопок по центру */
  }
`;

export const SupportButton = styled.div`
  background-color: #1e2636;
  border-radius: 12px;
  padding: 1vh 2vh 1vh 2vh; 
  display: flex;
  align-items: center;
  gap: 1vh; /* Уменьшаем разрыв между элементами */
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  flex: 1; /* Кнопки займут равное место */
  min-width: 100px; /* Минимальная ширина кнопки */
  max-width: 45%; /* Ограничиваем максимальную ширину */
  font-family: 'Rounded Mplus 1c', sans-serif;
  box-shadow: 0vh 0.3vh 0vh 0vh rgba(27, 140, 255, 1);
  transition: transform 0.3s ease;
  height: 6vh;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 344px) {
    align-items: center;
    font-size: 12px;
    height: 6vh;
    width: 80%; /* Кнопки занимают 80% ширины на маленьких экранах */
    margin: 0 auto; /* Центрируем кнопки */
  }

  div {
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Обрезаем текст, чтобы он не выходил за границы */
    text-overflow: ellipsis; /* Добавляем многоточие, если текст не помещается */
  }

  p {
    margin: 0;
    text-align: left;
    font-weight: 500;
  }

  span {
    font-size: 12px;
    text-align: left;
    color: #bbb;
    font-weight: 400;
  }
`;



export const SupportIcon = styled.img`
  width: 4vh;
  height: 4vh;
`;

export const DocumentLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  align-items: center;
  margin-top: 4vh;
  margin-bottom: 20px;
  width: 100%;
`;

export const DocumentLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Rounded Mplus 1c', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 90%;
  
  &:hover {
    text-decoration: underline;
  }
`;

export const ArrowIcon = styled.img`
  width: 3.2vh;
  height: 3.2vh;
`;

export const FooterText = styled.p`
  font-size: 10px;
  max-width: 90%;
  margin: 0 auto;
  line-height: 1.5;
  opacity: 0.6;
  color: #bbb;
  text-align: left;
`;
