import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainer, ProviderContainer, ScrollContent, SectionTitle, BackButton, BackButtonImage, ProvidersList, ProviderItem, CheckboxWrapper, CheckboxInput, CheckboxIcon, ProviderLabel, ActionButtons, Button } from './Provider.styles';

const ProviderSelector = ({ setSelectedProviders, language }) => {
    const navigate = useNavigate();
    const allProviders = ['Bgaming']; 
    const [providers, setProviders] = useState(allProviders); 

    useEffect(() => {
      const savedProviders = localStorage.getItem('selectedProviders');
      if (savedProviders) {
        setProviders(JSON.parse(savedProviders));
      } else {
        localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
      }
    }, []);

    const toggleProvider = (providerName) => {
      const updatedProviders = providers.includes(providerName)
        ? providers.filter((name) => name !== providerName)
        : [...providers, providerName];

      setProviders(updatedProviders);
    };

    const isChecked = (providerName) => providers.includes(providerName);

    const handleSave = () => {
      localStorage.setItem('selectedProviders', JSON.stringify(providers)); 
      setSelectedProviders(providers);  
      navigate('/'); 
    };

    return (
      <MainContainer>
        <ProviderContainer>
          <ScrollContent>
            <SectionTitle>{language === 'ru' ? 'Провайдеры' : 'Providers'}</SectionTitle>
            <BackButton onClick={() => navigate('/')}>
              <BackButtonImage src="/Wallet-Page/Deposit/backButton.png" alt="Back" />
            </BackButton>
            <ProvidersList>
              {allProviders.map((provider) => (
                <ProviderItem key={provider}>
                  <CheckboxWrapper>
                    <CheckboxInput
                      type="checkbox"
                      checked={isChecked(provider)}
                      onChange={() => toggleProvider(provider)}
                    />
                    <CheckboxIcon checked={isChecked(provider)} />
                  </CheckboxWrapper>
                  <ProviderLabel onClick={() => toggleProvider(provider)}>
                    {provider}
                  </ProviderLabel>
                </ProviderItem>
              ))}
            </ProvidersList>
            <ActionButtons>
              <Button onClick={() => setProviders([])}>{language === 'ru' ? 'Сбросить' : 'Reset'}</Button>
              <Button primary onClick={handleSave}>{language === 'ru' ? 'Показать' : 'Show'}</Button>
            </ActionButtons>
          </ScrollContent>
        </ProviderContainer>
      </MainContainer>
    );
};

export default ProviderSelector;
