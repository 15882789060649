const serverUrl = 'https://api.betongame.club';

const extractChatIdFromInitData = (initData) => {
  if (!initData) {

    return null;
  }

  const params = new URLSearchParams(initData);
  const userJsonString = params.get('user');

  if (!userJsonString) {
    return null;
  }

  let user;
  try {
    user = JSON.parse(userJsonString);
  } catch (error) {
    return null;
  }

  if (!user || !user.id) {
    return null;
  }

  return user.id;
};


const getUserByChatId = async (initData) => {
  const userId = extractChatIdFromInitData(initData); 

  const response = await fetch(`${serverUrl}/api/users/${userId}`, {
    headers: {  
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}` 
    },
  });



  return await response.json();
};

const getUserLanguage = async (initData) => {
  const chatId = extractChatIdFromInitData(initData); 

  const response = await fetch(`${serverUrl}/api/users/language/${chatId}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}` 
    },
  });


  const data = await response.json();
  return data.languageCode;
};

const getUserBalance = async (initData) => {
  const chatId = extractChatIdFromInitData(initData); 

  const response = await fetch(`${serverUrl}/api/users/balance/${chatId}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
  });

 

  return await response.json();
};

const updateUserBalance = async (initData, amount) => {
  const userId = extractChatIdFromInitData(initData);
  
  const response = await fetch(`${serverUrl}/api/users/update-balance`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
    body: JSON.stringify({ userId, amount }),
  });


  return await response.json();
};


const getMinDeposit = async (initData) => {
  const response = await fetch(`${serverUrl}/api/adminSettings/min-deposit`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
  });

  

  const data = await response.json();
  return data.minDeposit;
};

const startGameSession = async (gameIdentifier, initData, balance, language, src, name) => {
  const chatId = extractChatIdFromInitData(initData); 
  const provider = 'Bgaming';
  
  try {
    const response = await fetch(`${serverUrl}/sessions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${initData}`, 
      },
      body: JSON.stringify({
        gameIdentifier,
        userId: chatId,
        balance,
        language,
        provider,
        src,
        name
      })
    });

    
    const data = await response.json();
    return data.gameUrl;
  } catch (error) {
    throw new Error('Не удалось начать игровую сессию');
  }
};

const startGameDemo = async (gameIdentifier, initData, language, src, name) => {
  const chatId = extractChatIdFromInitData(initData); 
  const provider = 'Bgaming';
  
  try {
    const response = await fetch(`${serverUrl}/demo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${initData}`, 
      },
      body: JSON.stringify({
        gameIdentifier,
        userId: chatId,
        language,
        provider,
        src,
        name
      })
    });

   
    const data = await response.json();
    return data.gameUrl;
  } catch (error) {
    throw new Error('Не удалось начать игровую сессию');
  }
};

const updateWagering = async (initData, depositAmount) => {
  const userId= extractChatIdFromInitData(initData);

  const response = await fetch(`${serverUrl}/api/adminSettings/update-wagering-front`, {
    method: 'POST',                         
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`,
    },
    body: JSON.stringify({ userId, depositAmount }),
  });

  

  return await response.json();
};

const getRemainingWagering = async (initData) => {
  const userId = extractChatIdFromInitData(initData);

  const response = await fetch(`${serverUrl}/api/adminSettings/get-wagering?userId=${userId}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`,
    },
  });

 

  const data = await response.json();
  return data.remainingWagering;
};

const getHistoryGames = async (initData, provider) => {
  const userId = extractChatIdFromInitData(initData);

  const response = await fetch(`${serverUrl}/api/users/history/${userId}?provider=${encodeURIComponent(provider)}`, { 
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`,
    },
  });

 

  return await response.json();
};


const getFavoriteGames = async (initDataRaw) => {
  const userId = extractChatIdFromInitData(initDataRaw);
  const response = await fetch(`${serverUrl}/api/users/favoriteGames/${userId}`, { 
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${initDataRaw}`,
    },
  });

  

  return await response.json();
};




const addToFavorites = async (initData, gameId, provider, name, src) => {
  const chatId = extractChatIdFromInitData(initData);

      const response = await fetch(`${serverUrl}/api/users/favorites/${chatId}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${initData}`, 
          },
          body: JSON.stringify({ gameId, provider, name, src }), 
      });


};



const removeFromFavorites = async (initData, gameId) => {
  const chatId = extractChatIdFromInitData(initData);

      const response = await fetch(`${serverUrl}/api/users/favorites/${chatId}/${gameId}`, {
          method: 'DELETE',
          headers: {
              'Authorization': `Bearer ${initData}`, 
          },
      });


};

const getNewGames = async (initDataRaw, provider) => {
  const response = await fetch(`${serverUrl}/api/users/newGames/${provider}`, {
      headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${initDataRaw}`,
      },
  });

  

  return await response.json();
};


const getPopularGames = async (initDataRaw, provider) => {
  const response = await fetch(`${serverUrl}/api/users/popularGames/${provider}`, {
      headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${initDataRaw}`,
      },
  });


  return await response.json();
};

const getAllGames = async (initData) => {
  const response = await fetch(`${serverUrl}/api/users/allGames`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`,
    },
  });



  return await response.json();
};

const getTransactions = async (initDataRaw, filter) => {
  const userId = extractChatIdFromInitData(initDataRaw);
  const queryParam = filter !== 'all' ? `?type=${filter}` : '';

  const response = await fetch(`${serverUrl}/api/transactions/history/${userId}?type=${filter}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initDataRaw}`,
    },
  });


  return await response.json();
};


export { 
  getUserByChatId, 
  getUserLanguage, 
  getUserBalance, 
  updateUserBalance, 
  startGameSession,
  startGameDemo,
  getMinDeposit,
  updateWagering,
  getRemainingWagering,
  getHistoryGames,  
  removeFromFavorites,
  addToFavorites,
  getFavoriteGames,
  getNewGames,
  getPopularGames,
  getAllGames,
  getTransactions
};
