import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MainContainer,
  ScrollContent,
  PoplarTitle,
  GameGrid,
  GameCard,
  GameImage,
  SearchContainer,
  InputContainer,
  RandomButton,
  RandomIcon ,
  SearchInput,
  SectionTitle,
  Dropdown,
  DropdownItem,
  NoGamesFoundContainer,
  NoGamesText,
  CheckText
} from './Search.styles';
import { getUserBalance, getAllGames, getPopularGames } from '../../services/userService';
import Loader from '../Loader/Loader';

const SearchGames = ({ initDataRaw, language }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [games, setGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [popularGames, setPopularGames] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [isLoading, setIsLoading] = useState(true); 
  const navigate = useNavigate();


  useEffect(() => {
    const fetchAllGames = async () => {
      try {
        const allGames = await getAllGames(initDataRaw); 
        setGames(allGames);
        setFilteredGames(allGames);
      } catch (error) {
      }
    };

    fetchAllGames();
  }, [initDataRaw]);
  

  useEffect(() => {
    const fetchPopularGames = async () => {
      try {
        const popular = await getPopularGames(initDataRaw, 'Bgaming');
        setPopularGames(popular);
      } catch (error) {
      } finally {
        setIsLoading(false); 
      }
    };

    fetchPopularGames();
  }, [initDataRaw]);

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredGames(games);
      setIsDropdownOpen(false);
      setSelectedGame(null);
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      const filtered = games.filter((game) =>
        game.name.toLowerCase().includes(lowerQuery)
      );
      setFilteredGames(filtered);
      setIsDropdownOpen(filtered.length > 0);

      if (filtered.length === 0) {
        setSelectedGame(null);
      }
    }
  }, [searchQuery, games]);

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show();
    window.Telegram.WebApp.BackButton.onClick(() => {
      navigate(-1);
    });


    return () => {
      window.Telegram.WebApp.BackButton.hide();
    };
  }, [navigate]);

  

  const handleSelectGame = (game) => {
    navigate('/game', { 
      state: { 
        gameIdentifier: game.game_identifier || game.game_id,
        gameImage: game.src,
        gameName: game.name,
        providerName: game.provider
      } 
    });
  };

  const handleRandomGameClick = () => {
    if (games && games.length > 0) {
      const randomGame = games[Math.floor(Math.random() * games.length)];
      handleSelectGame(randomGame); 
    }
  };

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <MainContainer noResults={filteredGames.length === 0}>
      <ScrollContent>
        <SearchContainer>
          <SectionTitle>{language === 'ru' ? 'Поиск игр' : 'Search games'}</SectionTitle>
          <InputContainer>
            <SearchInput
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={language === 'ru' ? 'Введите название игры' : 'Enter game name'}
            />
            <RandomButton onClick={handleRandomGameClick}>
              <RandomIcon src="/Search-Page/random.svg" alt="Random Game" />
              Random Game
            </RandomButton>
            {isDropdownOpen && (
              <Dropdown>
                {filteredGames.map((game) => (
                  <DropdownItem key={game.id} onClick={() => handleSelectGame(game)}>
                    {game.name}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </InputContainer>
        </SearchContainer>

        {searchQuery && filteredGames.length > 0 && (
          <>
            <PoplarTitle>{language === 'ru' ? 'Результаты поиска' : 'Search Results'}</PoplarTitle>
            <GameGrid>
              {filteredGames.map((game) => (
                <GameCard key={game.id} onClick={() => handleSelectGame(game)}>
                  <GameImage src={game.src} alt={game.name} />
                </GameCard>
              ))}
            </GameGrid>
          </>
        )}

        {searchQuery && filteredGames.length === 0 && (
          <NoGamesFoundContainer>
            <NoGamesText>{language === 'ru' ? 'Ничего не найдено' : 'No games found'}</NoGamesText>
            <CheckText>{language === 'ru' ? 'Проверьте правильность написания' : 'Check your spelling'}</CheckText>
          </NoGamesFoundContainer>
        )}

        {!searchQuery && popularGames.length > 0 && (
          <>
            <PoplarTitle>{language === 'ru' ? 'Популярные игры' : 'Popular Games'}</PoplarTitle>
            <GameGrid>
              {popularGames.map((game) => (
                <GameCard key={game.id} onClick={() => handleSelectGame(game)}>
                  <GameImage src={game.src} alt={game.name} />
                </GameCard>
              ))}
            </GameGrid>
          </>
        )}
      </ScrollContent>
    </MainContainer>
  );
};

export default SearchGames;
