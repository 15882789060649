import React from 'react';
import { 
  DeletionPopUpContainer, 
  DeletionPopUpHeader, 
  DeletionPopUpTitle, 
  DoneButton, 
  WalletDeleteOption, 
  WalletDeleteText, 
  WalletDeleteIcon,
  WalletDeleteTrashIcon
} from './WalletDeletionPopup.styles'; // Ensure these styles are in place
import { useTonConnectUI } from '@tonconnect/ui-react'; // Import TON Connect

const WalletDeletionPopup = ({ wallets, language, onClose }) => {
  const [tonConnectUI] = useTonConnectUI(); // Access TON Connect UI functions

  // Function to handle wallet disconnection
  const handleDeleteWallet = async () => {
    try {
      await tonConnectUI.disconnect(); // Disconnect the wallet using TON Connect
      console.log('Wallet disconnected successfully');
    } catch (error) {
      console.error('Error disconnecting wallet:', error);
    }
  };

  return (
    <DeletionPopUpContainer>
      <DoneButton onClick={onClose}>{language === 'ru' ? 'Готово' : 'Done'}</DoneButton>
      <DeletionPopUpHeader>
        <DeletionPopUpTitle>{language === 'ru' ? 'Removing wallets' : 'Done'}</DeletionPopUpTitle>
      </DeletionPopUpHeader>

      {wallets.map((wallet, index) => (
        <WalletDeleteOption key={index}>
          <WalletDeleteIcon src="/Wallet-Page/Deposit/Usdt.png" alt="USDT Icon" /> 
          <WalletDeleteText>{wallet.name} {wallet.shortenedAddress}</WalletDeleteText>
          <WalletDeleteTrashIcon 
            src="/Wallet-Page/Deposit/trash.png" 
            alt="Delete Wallet" 
            onClick={handleDeleteWallet} 
          />
        </WalletDeleteOption>
      ))}
    </DeletionPopUpContainer>
  );
};

export default WalletDeletionPopup;
