import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Main from './components/Main/Main';
import Wallet from './components/wallet/wallet';
import BalanceBar from './components/BalanceBar/BalanceBar';
import GamePage from './components/GamePage/GamePage';
import ProviderSelector from './components/providers/Provider';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import SearchGames from './components/Search/Search';

const AppContainer = styled.div`
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  overflow-x: hidden;
`;

const AppContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

const AppContentWithRouter = () => {
  const [initDataRaw, setInitDataRaw] = useState('');
  const [language, setLanguage] = useState('');
  const [selectedProviders, setSelectedProviders] = useState([]);  
  const location = useLocation(); 

  useEffect(() => {
    window.Telegram.WebApp.expand(); 
    window.Telegram.WebApp.isClosingConfirmationEnabled = true;
    // Инициализация данных
    const initDataRaw = window.Telegram.WebApp.initData;
    const params = new URLSearchParams(initDataRaw);
    const userJsonString = params.get('user'); 
    console.log("User JSON:", userJsonString);
  
    if (userJsonString) {
      const user = JSON.parse(decodeURIComponent(userJsonString)); 
      const language = user.language_code || 'en'; 
      console.log("Language:", language); 
      setLanguage(language); 
    }

    setInitDataRaw(initDataRaw); 

    // Настройка поведения при закрытии
    const closingBehaviorData = JSON.stringify({
      eventType: 'web_app_setup_closing_behavior',
      eventData: {
        need_confirmation: true,  
      },
    });

    window.parent.postMessage(closingBehaviorData, '*');

  }, []);

  return (
    <AppContainer>
      <AppContent>
        <Routes>
          <Route path="/" element={<Main initDataRaw={initDataRaw} language={language} selectedProviders={selectedProviders}/>} />
          <Route path="/game" element={<GamePage />} />
          <Route path="/search" element={<SearchGames initDataRaw={initDataRaw} language={language}/>} />
          <Route path="/providers" element={<ProviderSelector setSelectedProviders={setSelectedProviders} language={language} />} /> 
          <Route path="/wallet" element={<Wallet initDataRaw={initDataRaw} language={language} />} />
        </Routes>
      </AppContent>
      {location.pathname !== '/wallet' && location.pathname !== '/game' && <BalanceBar initDataRaw={initDataRaw} language={language} />}
    </AppContainer>
  );
};

const App = () => (
  <TonConnectUIProvider 
    manifestUrl="https://raw.githubusercontent.com/daanicccch/tonconnect-manifestBcasino.json/main/tonconnect-manifest.json"
    enableAndroidBackHandler={false}
  >
    <Router>
      <AppContentWithRouter />
    </Router>
  </TonConnectUIProvider>
);

export default App;
