import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const GamePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { gameUrl } = location.state || {}; 
  if (!gameUrl) {
    navigate('/');
    return null;
  }

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <iframe src={gameUrl} width="100%" height="100%" title="Game" style={{ border: 'none' }}></iframe>
    </div>
  );
};

export default GamePage;
