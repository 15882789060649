const serverUrl = 'https://api.betongame.club';

const extractChatIdFromInitData = (initData) => {
  const params = new URLSearchParams(initData);
  const userJsonString = params.get('user');
  const user = JSON.parse(userJsonString);
  return user.id; 
};

const getUserByChatId = async (initData) => {
  const userId = extractChatIdFromInitData(initData); 

  const response = await fetch(`${serverUrl}/api/users/${userId}`, {
    headers: {  
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}` 
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

const getUserLanguage = async (initData) => {
  const chatId = extractChatIdFromInitData(initData); 

  const response = await fetch(`${serverUrl}/api/users/language/${chatId}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}` 
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data.languageCode;
};

const getUserBalance = async (initData) => {
  const chatId = extractChatIdFromInitData(initData); 

  const response = await fetch(`${serverUrl}/api/users/balance/${chatId}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

const updateUserBalance = async (initData, amount) => {
  const userId = extractChatIdFromInitData(initData);
  
  const response = await fetch(`${serverUrl}/api/users/update-balance`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
    body: JSON.stringify({ userId, amount }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return await response.json();
};


const getMinDeposit = async (initData) => {
  const response = await fetch(`${serverUrl}/api/adminSettings/min-deposit`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data.minDeposit;
};

const startGameSession = async (gameIdentifier, initData, balance, language) => {
  const chatId = extractChatIdFromInitData(initData); 

  try {
    const response = await fetch(`${serverUrl}/sessions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${initData}`, 
      },
      body: JSON.stringify({
        gameIdentifier,
        userId: chatId,
        balance,
        language
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.gameUrl;
  } catch (error) {
    console.error('Ошибка при создании игровой сессии:', error);
    throw new Error('Не удалось начать игровую сессию');
  }
};

const updateWagering = async (initData, depositAmount) => {
  const userId= extractChatIdFromInitData(initData);

  const response = await fetch(`${serverUrl}/api/adminSettings/update-wagering-front`, {
    method: 'POST',                         
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`,
    },
    body: JSON.stringify({ userId, depositAmount }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return await response.json();
};

const getRemainingWagering = async (initData) => {
  const userId = extractChatIdFromInitData(initData);

  const response = await fetch(`${serverUrl}/api/adminSettings/get-wagering?userId=${userId}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${initData}`,
    },
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data.remainingWagering;
};


export { 
  getUserByChatId, 
  getUserLanguage, 
  getUserBalance, 
  updateUserBalance, 
  startGameSession,
  getMinDeposit,
  updateWagering,
  getRemainingWagering
};
