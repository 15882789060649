import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainContainer, FixedBackground, WhiteBarWithDots, GameGrid, SectionTitle, GameCard, GameImage, ScrollContent, GradientGrid } from './Main.styles';
import { getUserBalance, startGameSession } from '../../services/userService';
import Footer from '../Footer/Footer';
import BalanceBar from '../BalanceBar/BalanceBar';

const Main = ({ initDataRaw, language }) => {
  const [balance, setBalance] = useState(null);
  const [games, setGames] = useState([]);  // Держим игры в состоянии
  const [selectedProviders, setSelectedProviders] = useState([]); // Состояние для выбранных провайдеров
  const navigate = useNavigate();

  useEffect(() => {
    // Загружаем сохраненных провайдеров из localStorage при первом рендере
    const savedProviders = localStorage.getItem('selectedProviders');
    if (savedProviders) {
      setSelectedProviders(JSON.parse(savedProviders));
    } else {
      // Если ничего не сохранено, включаем всех провайдеров по умолчанию
      const allProviders = ['Bgaming'];
      setSelectedProviders(allProviders);
      localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
    }
  }, []);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await getUserBalance(initDataRaw);
        setBalance(response.balance);
      } catch (error) {
        console.error('Ошибка при получении баланса:', error);
      }
    };

    fetchBalance();
  }, [initDataRaw]);

  useEffect(() => {
    const loadGamesForProviders = async () => {
      if (!selectedProviders || selectedProviders.length === 0) {
        setGames([]);  // Если провайдеры не выбраны, очищаем список игр
        return;
      }

      const loadedGames = [];

      for (const provider of selectedProviders) {
        try {
          // Загружаем JSON с играми для каждого провайдера
          const gamesForProvider = await import(`../../data/${provider}.json`);
         

          if (gamesForProvider && gamesForProvider.default) {
            loadedGames.push(...gamesForProvider.default); // добавляем игры провайдера
          }
        } catch (error) {
          console.error(`Ошибка загрузки игр для провайдера ${provider}:`, error);
        }
      }

      setGames(loadedGames);
    };

    loadGamesForProviders();
  }, [selectedProviders]);  // Загружаем игры, когда меняется список провайдеров

  const handleStartGame = async (gameIdentifier) => {
    try {
      const gameUrl = await startGameSession(gameIdentifier, initDataRaw, balance, language);
      navigate('/game', { state: { gameUrl } });
    } catch (error) {
      console.error('Ошибка при создании игровой сессии:', error);
    }
  };

  const renderGames = (games) => {
    return games.map((game) => (
      <GameCard key={game.id}>
        <GameImage
          src={game.src}
          alt={game.gameIdentifier}
          onClick={() => handleStartGame(game.gameIdentifier)}
        />
      </GameCard>
    ));
  };

  return (
    <MainContainer>
      <ScrollContent>
        <FixedBackground />
        <WhiteBarWithDots />
        
        <GradientGrid>
          <SectionTitle>{language === 'ru' ? 'Популярное' : 'Popular'}</SectionTitle>
          <GameGrid>
            {games.length > 0 ? renderGames(games) : <p>{language === 'ru' ? 'Нет игр' : 'No games available'}</p>}
          </GameGrid>
        </GradientGrid>
        <GradientGrid>

          <SectionTitle>{language === 'ru' ? 'Новинки' : 'New'}</SectionTitle>
          <GameGrid>
            {renderGames(games)}
          </GameGrid>
        </GradientGrid>

        <Footer language={language} />
      </ScrollContent>
      <BalanceBar />
    </MainContainer>
  );
};

export default Main;
