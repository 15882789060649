import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HistoryContainer,
  ScrollContent,
  GameGrid,
  SectionTitle,
  GameCard,
  GameImage,
  NoGamesMessage,
  ProvidersButton,
  SelectedProvidersCount,
  ProvidersIcon,
} from './History.styles';
import { getHistoryGames } from '../../services/userService';
import TabsNavigation from '../TabsNavigation/TabsNavigation'; 
import Footer from '../Footer/Footer';
import Loader from '../Loader/Loader';
import SelectedProviders from '../SelectedProviders/SelectedProviders'; 

const History = ({ initDataRaw, language }) => {
  const [historyGames, setHistoryGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [selectedProviders, setSelectedProviders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllProviders = () => {
      const providersFromStorage = JSON.parse(localStorage.getItem('allProviders'));
      if (providersFromStorage) {
        setSelectedProviders(providersFromStorage);
      }
    };

    fetchAllProviders();
  }, []);

  useEffect(() => {
    const fetchSelectedProviders = () => {
      const savedProviders = JSON.parse(localStorage.getItem('selectedProviders'));
      if (savedProviders && savedProviders.length > 0) {
        setSelectedProviders(savedProviders); 
      }
      setIsLoading(false); 
    };

    fetchSelectedProviders();
  }, []);

  useEffect(() => {
    const fetchHistoryGames = async () => {
      setIsLoading(true); 
      if (selectedProviders.length > 0) {
        const games = await getHistoryGames(initDataRaw, selectedProviders);
        const sortedGames = games.sort((a, b) => new Date(b.played_at) - new Date(a.played_at));
        setHistoryGames(sortedGames);
      } else {
        setHistoryGames([]);
      }
      setIsLoading(false);
    };

    fetchHistoryGames();
  }, [initDataRaw, selectedProviders]);

  const handleStartGame = (game) => {
    navigate('/game', { 
      state: { 
        gameIdentifier: game.game_id, 
        gameImage: game.src, 
        gameName: game.name, 
        providerName: game.provider 
      } 
    });
  };

  const handleProvidersClick = () => {
    navigate('/providers');
  };

  const handleRemoveProvider = (provider) => {
    const updatedProviders = selectedProviders.filter(p => p !== provider);
    setSelectedProviders(updatedProviders);
    localStorage.setItem('selectedProviders', JSON.stringify(updatedProviders));
    if(updatedProviders.length ==0){
      const allProviders = JSON.parse(localStorage.getItem('allProviders')) || [];
      setSelectedProviders(allProviders);
      localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
    }
  };

  const handleResetProviders = () => {
    const allProviders = JSON.parse(localStorage.getItem('allProviders')) || [];
    setSelectedProviders(allProviders);
    localStorage.setItem('selectedProviders', JSON.stringify(allProviders));
  };
  const renderGames = (games) => {
    return games.map((game) => (
      <GameCard key={game.id} onClick={() => handleStartGame(game)}>
        <GameImage
          src={game.src || '/path/to/default-image.png'} 
          alt={game.name || 'Game'} 
        />
      </GameCard>
    ));
  };

  if (isLoading) {
    return <Loader />; 
  }

  return (
    <ScrollContent>
      <HistoryContainer>
        <TabsNavigation language={language} />
        
        <ProvidersButton onClick={handleProvidersClick}>
          {language === 'ru' ? 'Все провайдеры' : 'All Providers'}
          {selectedProviders.length > 0 && (
            <SelectedProvidersCount>{selectedProviders.length}</SelectedProvidersCount>
          )}
          <ProvidersIcon src='/History-Page/arrow-down.svg' alt="Arrow" /> 
        </ProvidersButton>
        
        {selectedProviders.length > 0 && (
          <SelectedProviders
            selectedProviders={selectedProviders}
            onRemoveProvider={handleRemoveProvider}
            onResetProviders={handleResetProviders}
            language={language}
          />
        )}

        <SectionTitle>{language === 'ru' ? 'История' : 'History'}</SectionTitle>
        
        <GameGrid>
          {historyGames.length > 0 ? renderGames(historyGames) : 
            <NoGamesMessage>
              {language === 'ru' ? 'Пока ничего нет' : 'No games available'}
              <div>{language === 'ru' ? 'Тут будут игры в которые сыграете' : 'Here will be the games you play'}</div>
            </NoGamesMessage>
          }
        </GameGrid>

        <Footer language={language} />
      </HistoryContainer>
    </ScrollContent>
  );
};

export default History;
