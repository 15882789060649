import styled from 'styled-components';

export const BalanceBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(99.31deg, rgba(13, 16, 22, 0.2) -9.79%, rgba(17, 30, 54, 0.2) 20.84%, rgba(13, 16, 22, 0.2) 94.54%),
  linear-gradient(260.45deg, rgba(13, 16, 22, 0) -16.09%, rgba(17, 30, 54, 0.8) 5.44%, rgba(13, 16, 22, 0) 93.15%);
  
  padding: 1vh 3vh;
  backdrop-filter: blur(2vh);
  -webkit-backdrop-filter: blur(2vh);
  z-index: 100;
  box-sizing: border-box;
  padding-top: 3vh;
  padding-bottom: 3vh;
`;

export const IconContainer = styled.div`
  display: flex;
  gap: 2vh;
  align-items: center;
  padding-left: 3vh;

  @media (max-width: 415px) {
    padding-left: 2vh;
    gap: 2vh;
  }
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5vh;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 480px) {
    gap: 0.5vh;
  }
`;

export const NavText = styled.p`
  color: white;
  font-size: 0.8em;
  font-family: 'Rounded Mplus 1c', sans-serif;
  padding-top: 0;
  margin: 0;


`;

export const BalanceContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5vh 0vh 0.5vh 4vh;
  background: rgba(255, 255, 255, 0.1); 
  border-radius: 10px;
  height: 6vh;
`;

export const BalanceText = styled.p`
  color: #aaa;
  margin-bottom: 0;
  margin-right: 2vh;
  padding-top: 0;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-size: 2vh;

  @media (max-width: 430px) {
    font-size: 1.8vh;
    margin-right: 1.5vh;
  }

  @media (max-width: 415px) {
    font-size: 1.7vh;
    margin-right: 1.4vh;
  }

  @media (max-width: 391px) {
    font-size: 1.6vh;
    margin-right: 1.2vh;
  }

  @media (max-width: 360px) {
    font-size: 1.5vh;
    margin-right: 1vh;
  }

  @media (max-width: 344px) {
    font-size: 1.4vh;
    margin-right: 0.8vh;
  }

  @media (max-width: 320px) {
    font-size: 1.3vh;
    margin-right: 0.6vh;
  }
`;


export const BalanceAmount = styled.p`
  color: white;
  margin-right: 3vh;
  margin-top: 0;
  font-size: 2.2vh;
  text-align: center;
  font-family: 'Rounded Mplus 1c', sans-serif;
  font-weight: 500;


  @media (max-width: 412px) {
    font-size: 2vh;
    margin-right: 2vh;
  }
`;

export const Icon = styled.img`
  width: 3.5vh;
  height: 3.5vh;
  margin-bottom: 0;
  }
`;

export const WalletButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7vh;
  height: 7vh;
  cursor: pointer;
  border-radius: 20%;
  background-color: #343c4d;
  position: relative;
  box-shadow: 0vh 0.2vh 0vh 0vh rgba(59, 248, 112, 1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    width: 7vh;
    height: 7vh;
  }

  @media (max-width: 480px) {
    width: 6.5vh;
    height: 6.5vh;
  }
`;

export const WalletButtonShadow = styled.div`
  border-radius: 20%;
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.2);

  @media (max-width: 768px) {
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.2);
  }

  @media (max-width: 480px) {
    box-shadow: 0 0 12px rgba(0, 255, 0, 0.2);
  }
`;

export const WalletIcon = styled.img`
  width: 4vh;
  height: 4vh;
  object-fit: cover;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    width: 4vh;
    height: 4vh;
  }

  @media (max-width: 480px) {
    width: 4vh;
  height: 4vh;
  }
`;
